import { format, intervalToDuration } from 'date-fns'
import dayjs from 'dayjs'

export function formatToInternationalPattern(_date: string): string {
    const [date, time] = _date.split(' ')

    const [day, month, year] = date.split('/')

    return `${year}-${month}-${day} ${time}`
}

export function formatToDatetimeWithoutSeconds(date: string | Date | number): string {
    return format(new Date(date), 'dd/MM/yyyy HH:mm')
}

export function formatToTimeWithoutSeconds(date: string | Date | number): string {
    return format(new Date(date), 'HH:mm')
}

export function formatDurationToTime(duration: number): string {
    const { hours, minutes, seconds } = intervalToDuration({
        start: new Date(0),
        end: new Date(duration)
    })

    return `${hours}h ${minutes}m ${seconds}s`
}

export function formatDurationToTimeWithDays(duration: number): string {
    const { days, hours, minutes, seconds } = intervalToDuration({
        start: new Date(0),
        end: new Date(duration)
    })

    return `${days}d ${hours}h ${minutes}m ${seconds}s`
}

export function combineDateAndTime(dateString?: string, timeString?: string): string | undefined {
    if (!dateString || !timeString) return undefined
    const [hours, minutes] = timeString.split(':');
    return dayjs(dateString)
        .hour(parseInt(hours, 10))
        .minute(parseInt(minutes, 10))
        .second(0)
        .millisecond(0)
        .toISOString();
  };