import CompanyInfoChip from './CompanyInfoChip'
import CompanyInfoGrid from './CompanyInfoGrid'
import CompanyInfoInputs from './CompanyInfoInputs'
import CompanyInfoRoot from './CompanyInfoRoot'

export const CompanyInfo = {
    Root: CompanyInfoRoot,
    Inputs: CompanyInfoInputs,
    Grid: CompanyInfoGrid,
    Chip: CompanyInfoChip
}
