import useSWR from 'swr'

import ApiService from '@/services/Api'

export function useFetch<Data = any>(url: string, callback: (data: any) => void) {
    const { data, error } = useSWR<Data>(url, async url => {
        const { data } = await ApiService.get(url)

        callback(data)

        return data
    })

    return { data, error }
}
