import { makeStyles } from '@mui/styles'

export const menuWidth = '200px'

const useStyles = makeStyles(theme => ({
    drawer: {
        width: menuWidth
    },
    menuList: {
        width: menuWidth,
        color: theme.palette.secondary.contrastText
    },
    menuListIcon: {
        color: theme.palette.secondary.contrastText
    },
    listItemActive: {
        position: 'relative',
        '&:before': {
            position: 'absolute',
            content: 'close-quote',
            width: '4px',
            height: '100%',
            left: 0,
            top: 0,
            backgroundColor: theme.palette.primary.main
        }
    }
}))

export default useStyles
