import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { CompanyInfo } from '@/components/CompanyInfo'
import Divider from '@/components/Divider'
import { useFetch } from '@/hooks/useFetch'
import useDidMount from '@/hooks/useMount'
import { CompanyType } from '@/pages/Intranet/Companies'
import ApiService from '@/services/Api'
import {
    Grid,
    Card,
    CardContent
} from '@mui/material'

import useStyles from './styles'

const Infos: React.FC = () => {
    const classes = useStyles()

    const params: any = useParams()
    const { companyUuid } = params

    const [company, setCompany] = useState<CompanyType>()

    const getCompany = async () => {
        const { data } = await ApiService.get(`/company/${companyUuid}`) // CompanyType

        setCompany(data)
    }

    useFetch<CompanyType>(
        `/company/${companyUuid}`,
        (data: CompanyType) => {
            setCompany(data)
        }
    )

    useDidMount(() => {
        getCompany()
    })
    return (
        <Card>
            <CardContent className={classes.cardContent}>
                <Grid container>
                    <CompanyInfo.Grid xs={12} md={4} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='UUID' value={companyUuid} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={5} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Nome da empresa' value={company?.name} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={5} md={2} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Empresa ativa' value={company?.enabled === undefined ? 'Não cadastrado' : (company?.enabled ? 'Sim' : 'Não')} />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={5} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Nome da corporação' value={company?.corporateName ? company?.corporateName : 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Frequência' value={company?.frequency || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Site' value={company?.site || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='CNPJ/CPF' value={company?.cnpjCpf || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Fistel' value={company?.fistel || 'Não cadastrado'} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Email' value={company?.email || 'Não cadastrado'} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Email do financeiro' value={company?.payerEmail || 'Não cadastrado'} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Número de celular' value={company?.phoneNumber || 'Não cadastrado'} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Número de celular do financeiro' value={company?.payerPhoneNumber || 'Não cadastrado'} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Chip label="WhatsApp Ativo" active={company?.whatsappEnabled} />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Data de criação' value={company?.createdAt ? dayjs(company?.createdAt).format('DD/MM/YYYY HH:mm') : 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Data da última atualização' value={company?.updatedAt ? dayjs(company?.updatedAt).format('DD/MM/YYYY HH:mm') : 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Segmento' value={company?.segments ? company?.segments.join(', ') : ''} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                </Grid>
                <Divider size={3} />
                <Grid container>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Estado' value={company?.address?.state || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Cidade' value={company?.address?.city || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Bairro' value={company?.address?.neighborhood || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Rua' value={company?.address?.street || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Numero' value={company?.address?.number || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Complemento' value={company?.address?.complement || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                </Grid>
                <Divider size={3} />
                <Grid container>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='ID do adstream/icecast' value={company?.streamId} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Quantidade de usuários' value={company?.users?.length || 0} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Código do Anydesk' value={company?.anydeskCode} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Senha do Anydesk' value={company?.anydeskPassword} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Número de telefone do Suporte' value={company?.supportPhonenumber} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={6} md={3} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='Senha do stream' value={company?.streamPassword} copy hidden />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                </Grid>
                <Divider size={3} />
                <Grid container>
                    <CompanyInfo.Grid xs={12} md={4} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='URL do stream externo' value={company?.externalIcecastUrl || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={12} md={4} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Inputs label='URL do servidor de Streaming (Loopert)' value={company?.streamingServerUrl || 'Não cadastrado'} copy />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                    <CompanyInfo.Grid xs={12} md={4} >
                        <CompanyInfo.Root>
                            <CompanyInfo.Chip label='Adstream Ativo' active={company?.adstreamEnabled} />
                        </CompanyInfo.Root>
                    </CompanyInfo.Grid>
                </Grid>
                <Divider size={3} />
                <Grid container>
                </Grid>
            </CardContent >
        </Card >
    )
}

export default Infos
