import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import Divider from '@/components/Divider'
import useDidMount from '@/hooks/useMount'
import ApiService from '@/services/Api'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Typography
} from '@mui/material'

import useStyles from './styles'

interface IConnection {
    uuid?: string,
    companyUuid?: string,
    action?: string,
    createdAt?: string,
    updatedAt?: string
}

const Connections: React.FC = () => {
    const classes = useStyles()

    const params: any = useParams()
    const { companyUuid } = params

    const [connections, setConnections] = useState<IConnection[]>([])

    const getConnections = async () => {
        const { data } = await ApiService.get(`/icecast/connections/${companyUuid}`)

        setConnections(data)
    }

    useDidMount(() => {
        getConnections()
    })

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Typography className={classes.titleText}>
                        Conexões do Icecast
                    </Typography>
                </Grid>
            </Grid>

            <Divider size={3} />
            <Grid container>
                <TableContainer component={Paper} className={classes.table}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Ação</TableCell>
                                <TableCell>Data</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                connections?.map((connection) => (
                                    <TableRow key={connection.uuid}>
                                        <TableCell>{connection.action === 'connect' ? 'Conexão' : 'Desconexão'}</TableCell>
                                        <TableCell>{dayjs(connection.createdAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}

export default Connections
