import React, { ReactNode } from 'react'

import { Grid, GridSize } from '@mui/material'

import useStyles from './styles'

interface CompanyInfoGridProps {
    children: ReactNode
    xs?: GridSize
    md?: GridSize
}

export default function CompanyInfoGrid({ children, xs, md }: CompanyInfoGridProps) {
    const classes = useStyles()

    return (
        <Grid className={classes.gridItem} item xs={xs} md={md}>
            {children}
        </Grid>
    )
}
