import React, { ReactNode } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
import { Link, useLocation } from 'react-router-dom'

import {
    RadioOutlined as CompanyIcon,
    NotificationImportant,
    RecordVoiceOverOutlined,
    AccountCircleOutlined,
    PhoneAndroidOutlined as PhoneIcon
} from '@mui/icons-material'
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material'

import useStyles from './styles'

type MenuProps = {
    opened: boolean
    onClose: () => void
}

type MenuItem = {
    title: string,
    to: string,
    icon: ReactNode,
    isComing: boolean
}

const Menu: React.FC<MenuProps> = (props) => {
    const { opened, onClose } = props

    const classes = useStyles()

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const location = useLocation()

    const isMenuItemActive = (route: string) => location.pathname.includes(route)

    const menuItems: MenuItem[] = [{
        title: 'Empresas',
        to: '/companies',
        icon: <CompanyIcon />,
        isComing: false
    }, {
        title: 'Campanhas',
        to: '/campaigns',
        icon: <RecordVoiceOverOutlined />,
        isComing: false
    }, {
        title: 'Avisos',
        to: '/notices',
        icon: <NotificationImportant />,
        isComing: false
    }, {
        title: 'Usuários',
        to: '/users',
        icon: <AccountCircleOutlined />,
        isComing: false
    }, {
        title: 'Aplicativos',
        to: '/apps',
        icon: <PhoneIcon />,
        isComing: false
    }]

    return (
        <Drawer
            open={opened}
            variant="temporary"
            anchor="left"
            onClose={onClose}
            className={classes.drawer}
        >
            <List className={classes.menuList}>
                {
                    menuItems.map(item => (
                        <ListItem
                            button
                            key={item.to}
                            to={item.to}
                            onClick={onClose}
                            component={Link}
                            className={`${isMenuItemActive(item.to) ? classes.listItemActive : ''}`}
                            disabled={item.isComing}
                        >
                            <ListItemIcon className={classes.menuListIcon}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ))
                }
            </List>
        </Drawer>
    )
}

export default Menu
