import React, { useEffect, useState } from "react";
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    Divider,
    ListItemText,
    DialogContent,
    Typography,
    Box,
    TablePagination
} from "@mui/material";
import { EditOutlined as EditIcon, DeleteOutline as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import useStyles from "./style";
import { SelectChangeEvent } from "@mui/material/Select";
import ApiService from "@/services/Api";
import { useSnackbar } from 'notistack';
import { debounce } from "lodash";

interface IApp {
    uuid?: string;
    backgroundColor?: string;
    mainColor?: string;
    secondaryColor?: string;
    textColor?: string;
    companyUuids: string[];
}

interface IAddress {
    uuid: string;
    country: string;
    state: string;
    city: string;
    neighborhood: string | null;
    street: string | null;
    number: string | null;
    complement: string | null;
    latitude: number | null;
    longitude: number | null;
}

interface IRadio {
    uuid: string;
    name: string;
    address: IAddress;
}

const debouncedUpdate = debounce((callback) => callback(), 300)

const Apps: React.FC = () => {

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(6);
    const [radios, setRadios] = useState<IRadio[]>([]);
    const [openedAppDialog, setOpenedAppDialog] = useState(false);
    const [app, setApp] = useState<IApp | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [companies, setCompanies] = useState<string[]>([]);
    const [apps, setApps] = useState<IApp[]>([]);
    const [openedCompaniesDialog, setOpenedCompaniesDialog] = useState(false);
    const [openedDeleteAppDialog, setOpenedDeleteAppDialog] =
        useState<boolean>(false);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        const loadRadios = async () => {
            try {
                const response = await ApiService.get("/company/list");
                setRadios(response.data);
            } catch (error) {
                console.error("Erro ao buscar rádios: ", error);
            }

        };
        loadRadios();
    }, []);

    const loadApps = async () => {
        try {
            const response = await ApiService.get("/app/list");
            setApps(response.data);
        } catch (error) {
            console.error("Erro ao buscar apps: ", error);
        }
    };

    useEffect(() => {
        loadApps();
    }, []);

    const handleChange = (event: SelectChangeEvent<typeof companies>) => {
        const {
            target: { value },
        } = event;

        const newValue = Array.isArray(value) ? value : value.split(",");
        setCompanies(newValue);
    };

    const handleOpenAppDialog = (appToEdit?: IApp) => {
        const initialApp = appToEdit || {
            backgroundColor: "#ffffff",
            mainColor: "#ffffff",
            secondaryColor: "#ffffff",
            textColor: "#ffffff",
            companyUuids: []
        };

        setApp(initialApp);
        setCompanies(initialApp.companyUuids);
        setOpenedAppDialog(true);
    };

    const handleCloseAppDialog = () => {
        setOpenedAppDialog(false);
        setApp(null);
    };

    const saveApp = async () => {
        if (!app) return;
        try {
            const appData = {
                ...app,
                companyUuids: companies,
            };
            const endpoint = app.uuid ? `/app/${app.uuid}` : "/app";
            const method = app.uuid ? ApiService.put : ApiService.post;

            const response = await method(endpoint, appData);

            if (response.data) {
                enqueueSnackbar("Aplicativo salvo com sucesso.", { variant: "success" });
                handleCloseAppDialog();
                await loadApps();
                setApp(null);
            }

        } catch (error: any) {
            console.error("Erro ao salvar aplicativo:", error);
            enqueueSnackbar(`${error.data}`, { variant: "error" });
        }
    };

    const openCompaniesDialog = (app: IApp) => {
        setApp(app);
        setOpenedCompaniesDialog(true);
    };

    const handleCloseCompaniesDialog = () => {
        setOpenedCompaniesDialog(false);
        setApp(null);
    };

    const openDeleteAppDialog = async (app: IApp) => {
        setApp(app);
        setOpenedDeleteAppDialog(true);
    };

    const handleDeleteApp = async () => {
        try {
            await ApiService.delete(
                `/app/${app?.uuid}`
            );
            setApp(null);
            setOpenedDeleteAppDialog(false);
            setApps(apps.filter((item) => item.uuid !== app?.uuid));
            enqueueSnackbar("Aplicativo removido com sucesso.", {
                variant: "success",
            });
        } catch (error: any) {
            enqueueSnackbar(
                error?.data || "Ocorreu um erro ao remover o aplicativo.",
                {
                    variant: "error",
                }
            );
        }


    };
    const updateAppColor = (colorKey: string, value: string) => {
        if (!app) return;

        debouncedUpdate(() => {
            setApp({
                ...app,
                [colorKey]: value
            });
        });
    }

    const filteredApps = apps.filter((app) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        const matchesCompany = lowerCaseSearchTerm ? app.companyUuids.some((uuid) => {
            const company = radios.find((company: IRadio) => company.uuid === uuid);
            return company && company.name.toLowerCase().includes(lowerCaseSearchTerm);
        }) : apps;

        return matchesCompany;
    });

    const handleChangePage = (_: unknown, newPage: React.SetStateAction<number>) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const appsToDisplay = filteredApps.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Buscar por rádio"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenAppDialog()}
                        size="small"
                    >
                        Criar novo aplicativo
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="apps table" >
                            <TableHead>
                                <TableRow sx={{ height: '30px' }}>
                                    <TableCell align="left">Rádio</TableCell>
                                    <TableCell align="center">Cor de fundo</TableCell>
                                    <TableCell align="center">Cor principal</TableCell>
                                    <TableCell align="center">Cor secundária</TableCell>
                                    <TableCell align="center">Cor do Texto</TableCell>
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appsToDisplay.map((app) => (
                                    <TableRow key={app.uuid} sx={{ height: '30px' }}>
                                        <TableCell align="left" >
                                            {app.companyUuids.length > 1 ? (
                                                <Button onClick={() => openCompaniesDialog(app)}>
                                                    Ver Rádios ({app.companyUuids.length})
                                                </Button>
                                            ) : (
                                                radios.find(r => r.uuid === app.companyUuids[0])?.name || "Nenhuma rádio"
                                            )}
                                        </TableCell>
                                        <TableCell align="center">
                                            <Box
                                                sx={{
                                                    width: '24px',
                                                    height: '24px',
                                                    backgroundColor: app.backgroundColor,
                                                    borderRadius: '4px',
                                                    border: '1px solid #ccc',
                                                    display: 'inline-block',
                                                }}
                                                title={app.backgroundColor}
                                            />
                                        </TableCell>
                                        <TableCell align="center">  <Box
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                backgroundColor: app.mainColor,
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                display: 'inline-block',
                                            }}
                                            title={app.mainColor}
                                        /></TableCell>
                                        <TableCell align="center">  <Box
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                backgroundColor: app.secondaryColor,
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                display: 'inline-block',
                                            }}
                                            title={app.secondaryColor}
                                        /></TableCell>
                                        <TableCell align="center">  <Box
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                                backgroundColor: app.textColor,
                                                borderRadius: '4px',
                                                border: '1px solid #ccc',
                                                display: 'inline-block',
                                            }}
                                            title={app.textColor}
                                        /></TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleOpenAppDialog(app)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => openDeleteAppDialog(app)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[6, 7, 8, 9, 10]}
                            component="div"
                            count={filteredApps.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog
                open={openedCompaniesDialog}
                onClose={handleCloseCompaniesDialog}
            >
                <DialogTitle>Radios Associadas</DialogTitle>
                <DialogContent>
                    <Typography>
                        {app?.companyUuids
                            .map((uuid) => {
                                const company = radios.find(
                                    (r) => r.uuid === uuid
                                );
                                return company ? company.name : "";
                            })
                            .filter(Boolean)
                            .join(", ")}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCompaniesDialog} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openedAppDialog}>
                <DialogTitle>
                    {!app?.uuid
                        ? "Criando novo aplicativo"
                        : "Editando o aplicativo"}
                </DialogTitle>
                {app && (
                    <>
                        <Grid container spacing={2} style={{ padding: "16px" }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Cor de fundo"
                                    size="small"
                                    variant="outlined"
                                    type="color"
                                    value={app?.backgroundColor}
                                    onChange={(e) => {
                                        updateAppColor("backgroundColor", e.target.value)
                                    }}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Cor principal"
                                    size="small"
                                    variant="outlined"
                                    type="color"
                                    value={app?.mainColor}
                                    onChange={(e) => {
                                        updateAppColor("mainColor", e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Cor secundária"
                                    size="small"
                                    variant="outlined"
                                    type="color"
                                    value={app?.secondaryColor}
                                    onChange={(e) => {
                                        updateAppColor("secondaryColor", e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Cor de texto"
                                    size="small"
                                    variant="outlined"
                                    type="color"
                                    value={app?.textColor}
                                    onChange={(e) => {
                                        updateAppColor("textColor", e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    size="small"
                                    style={{ width: 250 }}
                                >
                                    <InputLabel id="associatedRadios">Rádios Associadas</InputLabel>
                                    <Select
                                        labelId="associatedRadios"
                                        multiple
                                        value={companies}
                                        variant="outlined"
                                        onChange={handleChange}
                                        label="Rádios Associadas"
                                        renderValue={(selected) =>
                                            selected
                                                .map((uuid) => {
                                                    const radio = radios.find(
                                                        (r) => r.uuid === uuid
                                                    );
                                                    return radio
                                                        ? `${radio.name} - ${radio.address.city}`
                                                        : "";
                                                })
                                                .join(", ")
                                        }
                                        MenuProps={MenuProps}
                                        className={classes.select}
                                    >
                                        {radios.map((radio) => (
                                            <MenuItem
                                                key={radio.uuid}
                                                value={radio.uuid}
                                            >
                                                <Checkbox
                                                    checked={
                                                        companies.indexOf(
                                                            radio.uuid
                                                        ) > -1
                                                    }
                                                />
                                                <ListItemText
                                                    primary={`${radio.name} - ${radio.address.city}`}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Divider />

                        <DialogActions>
                            <Button
                                onClick={handleCloseAppDialog}
                                color="primary"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={saveApp}
                                color="primary"
                                variant="contained"
                            >
                                {app?.uuid ? "Salvar" : "Criar"}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog >
            <Dialog
                aria-labelledby="simple-dialog-title"
                open={openedDeleteAppDialog}
            >
                <DialogTitle>
                    Tem certeza que deseja remover este aplicativo?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpenedDeleteAppDialog(false)}
                        className={classes.deleteAppDialogCancelButton}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="primary"
                        autoFocus
                        onClick={handleDeleteApp}
                    >
                        Remover
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Apps;
