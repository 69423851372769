import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    table: {
        background: theme.palette.secondary.light
    },
    titleText: {
        fontSize: 30,
        color: theme.palette.secondary.contrastText,
        fontWeight: 600,
        textAlign: 'center'
    }
}))

export default useStyles
