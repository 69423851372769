import React, { useContext } from 'react'

import { AuthContext } from '@/contexts/auth'
import {
    Menu as MenuIcon,
    ExitToApp as LogoutIcon
} from '@mui/icons-material'
import {
    Grid,
    IconButton,
    Button
} from '@mui/material'

import useStyles from './styles'

type NavbarProps = {
    onOpenMenu: () => void
}

const Navbar: React.FC<NavbarProps> = ({ onOpenMenu }) => {
    const { setUser, setCompany } = useContext(AuthContext)
    const classes = useStyles()

    const handleLogout = async () => {
        localStorage.removeItem('token')

        setUser(null)
        setCompany(null)
    }

    return (
        <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
            <Grid item>
                <IconButton onClick={onOpenMenu}>
                    <MenuIcon color="secondary" />
                </IconButton>
            </Grid>
            <Grid item >
                <Button color="secondary" onClick={handleLogout}>
                    Sair
                    <LogoutIcon color="secondary" className={classes.logoutIconButton} />
                </Button>
            </Grid>
        </Grid>
    )
}

export default Navbar
