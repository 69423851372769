import { makeStyles } from '@mui/styles'

import { navbarHeight } from './Navbar/styles'

const useStyles = makeStyles(theme => ({
    table: {
        height: 'calc(100vh - 80px)'
    },
    searchInput: {
        marginBottom: theme.spacing(3)
    },
    privateContainer: {
        height: '100%'
    },
    routesContent: {
        height: '100%',
        maxWidth: 1500,
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    routesContainer: {
        height: '100%',
        maxHeight: `calc(100vh - ${navbarHeight})`,
        // overflow: (props: StylesProps) => props.openDrawer ? "hidden" : "auto",
        flex: 1
    }
}))

export default useStyles
