export const maskCpf = (v?: string): string => {
    if (v && v?.length <= 11) {
        v = v.replace(/\D/g, '')
        return '' || (v ? v.replace(/(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4') : '')
    } else if (v && v?.length > 11) {
        v = v.replace(/\D/g, '')
        return '' || (v ? v.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, '$1.$2.$3/$4-$5') : '')
    } else {
        return ''
    }
}

export const maskPhone = (v?: string): string => {
    if (v && v?.length >= 0) {
        v = v.replace(/\D/g, '')
        return '' || (v ? v.replace(/(\d{2})(\d{1})(\d{4})(\d)/g, '($1) $2 $3-$4') : '')
    }
    return ''
}

export const unMask = (v?: string): string => {
    return '' || (v ? v.replace(/[^a-zA-Z0-9]/g, '') : '')
}
