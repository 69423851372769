import React from 'react'

import { Divider as MaterialDivider, useTheme } from '@mui/material'

import useStyles from './styles'

type DividerProps = {
    orientation?: 'horizontal' | 'vertical'
    size: number
}

const Divider: React.FC<DividerProps> = ({ orientation, size }) => {
    const theme = useTheme()

    const customProps = {
        ...(!orientation || orientation === 'horizontal' ? { height: theme.spacing(size), width: '100%' } : {}),
        ...(orientation === 'vertical' ? { height: 'auto', width: theme.spacing(size) } : {})
    }

    const classes = useStyles()

    return (
        <MaterialDivider
            orientation={orientation || 'horizontal'}
            className={classes.divider}
            style={{ ...customProps }}
        />
    )
}

export default Divider
