import React, { ReactNode } from 'react'

import useStyles from './styles'

interface CompanyInfoRootProps {
    children: ReactNode
}

export default function CompanyInfoRoot({ children }: CompanyInfoRootProps) {
    const classes = useStyles()

    return (
        <div className={classes.inputContainer}>
            {children}
        </div>
    )
}
