import React, { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { AuthContext } from '@/contexts/auth'
import Login from '@/pages/Auth/Login'
import Intranet from '@/pages/Intranet'
import {
    Grid,
    CircularProgress
} from '@mui/material'

import useStyles from './styles'

const RoutesApp = () => {
    const classes = useStyles()

    const { loading } = useContext(AuthContext)

    if (loading) {
        return (
            <Grid container className={classes.loadingContainer} justifyContent="center" alignContent="center">
                <CircularProgress size={80} color="primary" />
            </Grid>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/auth/login" element={<Login />} />
                <Route path="/*" element={<Intranet />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesApp
