import { SnackbarProvider } from 'notistack'
import React from 'react'
import { createRoot } from 'react-dom/client'

import App from '@/App'
import { AuthContextProvider } from '@/contexts/auth'
import { Collapse } from '@mui/material'
import { createTheme, adaptV4Theme, Theme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import 'fontsource-roboto'

import '@/styles/global.css'
declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface, no-unused-vars
    interface DefaultTheme extends Theme { }
}

const RootComponent = () => {
    const theme = createTheme(adaptV4Theme({
        palette: {
            background: {
                default: '#FAFAFA',
                paper: '#FAFAFA'
            },
            primary: {
                light: '#668BDC',
                main: '#2E5EAA',
                dark: '#00357A',
                contrastText: '#FAFAFA'
            },
            secondary: {
                light: '#FFFFFF',
                main: '#FAFAFA',
                dark: '#C7C7C7',
                contrastText: '#303030'
            },
            error: {
                light: '#ff8279',
                main: '#ff4d4d',
                dark: '#c50024',
                contrastText: '#FAFAFA'
            }
        }
    }))

    return (
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        TransitionComponent={Collapse}
                        preventDuplicate={true}
                    >
                        <AuthContextProvider>
                            <App />
                        </AuthContextProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.StrictMode>
    )
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

root.render(
    <RootComponent />
)
