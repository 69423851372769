import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    cardContent: {
        background: theme.palette.secondary.light
    },
    textTitle: {
        fontWeight: 600,
        color: theme.palette.secondary.contrastText
    },
    textValue: {
        color: theme.palette.secondary.contrastText,
        marginBottom: theme.spacing(1)
    },
    inputField: {
        width: '100%',
        marginBottom: theme.spacing(1),
        '& .MuiInputBase-root': {
            color: 'inherit'
        }
    },
    inputContainer: {
        position: 'relative',
        alignItems: 'center'
    },
    copyButton: {
        position: 'absolute',
        right: 2,
        top: 4,
        padding: 4,
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light
        }
    },
    gridItem: {
        paddingRight: theme.spacing(1),
        '&:not(:last-child)': {
            marginBottom: theme.spacing(1)
        }
    },
    showHideButton: {
        position: 'absolute',
        color: '#666',
        top: 4,
        padding: 4,
        right: 35,
        backgroundColor: theme.palette.secondary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light
        }
    },
    chipContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: 8
    },
    chipLabel: {
        position: 'absolute',
        top: -9,
        marginRight: theme.spacing(1),
        fontSize: 12,
        opacity: 0.38
    },
    chipInactive: {
        border: 'none',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#fff',
        backgroundColor: '#FC7373'
    },
    chipActive: {
        border: 'none',
        fontWeight: 'bold',
        fontSize: 14,
        color: '#fff',
        backgroundColor: '#7CF172'
    }
}))

export default useStyles
