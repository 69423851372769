import { makeStyles } from '@mui/styles'

export const navbarHeight = '50px'

const useStyles = makeStyles(theme => ({
    container: {
        background: theme.palette.primary.main,
        height: navbarHeight
    },
    logoutIconButton: {
        marginLeft: theme.spacing(1)
    }
}))

export default useStyles
