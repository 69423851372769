export type ApiConfigType = {
    apiUrl: string
    campaignApiUrl: string
    metricsApiUrl: string
}

const config: ApiConfigType = {
    apiUrl: process.env.REACT_APP_API_URL?.toString() || '',
    campaignApiUrl: process.env.REACT_APP_CAMPAIGN_API_URL?.toString() || '',
    metricsApiUrl: process.env.REACT_APP_METRICS_API_URL?.toString() || ''
}

export default config
