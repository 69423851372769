import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    divider: {
        backgroundColor: 'transparent',
        borderColor: 'transparent'
    }
})

export default useStyles
