import React, { useState } from 'react'

import Menu from '@/pages/Intranet/Menu'
import Navbar from '@/pages/Intranet/Navbar'
import IntranetRoutes from '@/routes/intranet'
import {
    Grid,
    Container
} from '@mui/material'

import useStyles from './styles'

const Intranet: React.FC = () => {
    const [openedMenu, setOpenedMenu] = useState<boolean>(false)

    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            wrap="nowrap"
        >
            <Navbar
                onOpenMenu={() => setOpenedMenu(true)}
            />

            <Grid
                container
                className={classes.privateContainer}
            >
                <Menu
                    opened={openedMenu}
                    onClose={() => setOpenedMenu(false)}
                />

                <Grid
                    container
                    justifyContent="center"
                    className={classes.routesContainer}
                >
                    <Container className={classes.routesContent}>
                        <IntranetRoutes />
                    </Container>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Intranet
