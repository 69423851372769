import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    cardContent: {
        background: theme.palette.secondary.light
    },
    textTitle: {
        fontWeight: 600,
        color: theme.palette.secondary.contrastText
    },
    textValue: {
        color: theme.palette.secondary.contrastText
    }
}))

export default useStyles
