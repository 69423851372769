import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    table: {
        background: theme.palette.secondary.light
    },
    companyDialogContainer: {
        // padding: theme.spacing(3)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogItemInput: {
        width: '100%',
        padding: theme.spacing(2)
    },
    itemInput: {
        width: '100%'
    },
    formControl: {
        minWidth: '100%'
    },
    select: {
        height: theme.spacing(5)
    },
    userPopoverDeleteButton: {
        background: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        '&:hover': {
            backgroundColor: theme.palette.error.dark
        }
    },
    deleteUserDialogCancelButton: {
        color: theme.palette.secondary.dark
    },
    titleText: {
        fontSize: 50,
        color: theme.palette.secondary.contrastText,
        fontWeight: 600,
        textAlign: 'center'
    }
}))

export default useStyles
