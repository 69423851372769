import React, { createContext, useState } from 'react'

import useMount from '@/hooks/useMount'
import apiService from '@/services/Api'

type CompanyType = {
    name: string
}

type UserType = {
    role: 'studio' | 'user'
}

type ProductType = 'codec' | 'premium'

type AuthContextProps = {
    logged: boolean,
    loading: boolean,
    user: UserType | null,
    company: CompanyType | null,
    product: ProductType,
    setUser: (user: UserType | null) => void
    setCompany: (company: CompanyType | null) => void
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

export const AuthContextProvider: React.FC<any> = ({ children }: any) => {
    const [user, setUser] = useState<UserType | null>({} as UserType)
    const [company, setCompany] = useState<CompanyType | null>({} as CompanyType)
    const [loading, setLoading] = useState<boolean>(true)

    const subdomain = window.location.host.split('.')[0]

    useMount(async () => {
        try {
            const { data } = await apiService.get('/auth/check')

            setUser(data.user)
            setCompany(data.company)
        } catch (e) {
            localStorage.removeItem('token')

            setUser(null)
            setCompany(null)
        }

        setTimeout(() => {
            setLoading(false)
        }, 2000)
    })

    return (
        <AuthContext.Provider
            value={{
                logged: !!user,
                user,
                company,
                loading,
                product: subdomain as ProductType,
                setUser,
                setCompany
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
