import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        width: '100%',
        height: '100%',
        zIndex: 9999,
        position: 'fixed',
        background: theme.palette.background.default
    }
}))

export default useStyles
