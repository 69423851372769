import React from 'react'

import { Typography, Chip } from '@mui/material'

import useStyles from './styles'
export interface CompanyInfoChipProps {
    label: string
    active: boolean | undefined
}

export default function CompanyInfoChip({ label, active }: CompanyInfoChipProps) {
    const classes = useStyles()

    return (
        <div className={classes.chipContainer}>
            <Typography className={classes.chipLabel}>
                {label}
            </Typography>
            {active
                ? (<Chip
                    style={{
                        backgroundColor:
                            'rgb(102, 187, 106)',
                        color: 'rgba(0, 0, 0, 0.87)'
                    }}
                    size="small"
                    label="Ativo"
                />)
                : (
                    <Chip
                        color="default"
                        size="small"
                        label="Inativo"
                    />
                )

            }
        </div>
    )
}
