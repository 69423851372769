import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'

import { ShowUserPasswordModal } from '@/components/Modals/ShowUserPasswordModal'
import frontendConfig from '@/config/frontend'
import {
    CodecRoleType,
    ProductType,
    IntranetRoleType
} from '@/pages/Intranet/Companies'
import ApiService from '@/services/Api'
import {
    EditOutlined as EditIcon, Add as AddIcon,
    DeleteOutline as DeleteIcon,
    LaunchOutlined as AccessUserAccountIcon,
    VpnKeyOutlined as VpnKeyIcon
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    IconButton,
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    Divider,
    Typography,
    ListItemText,
    DialogContent,
    Chip,
    TablePagination
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import useStyles from './style'

interface IUser {
    uuid?: string;
    companyUuid: string;
    name: string;
    email: string;
    product: ProductType
    role: CodecRoleType | IntranetRoleType
    pictureKey: string | null;
    preferences: any;
    isBetaTester: boolean;
    createdAt: string;
    lastHeardAt: string | null;
    updatedAt: string;
    companyUuids: string[];
}

interface IAddress {
    uuid: string;
    country: string;
    state: string;
    city: string;
    neighborhood: string | null;
    street: string | null;
    number: string | null;
    complement: string | null;
    latitude: number | null;
    longitude: number | null;
}

interface IRadio {
    uuid: string;
    name: string;
    address: IAddress;
}

const Users: React.FC = () => {
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()
    const [radios, setRadios] = useState<IRadio[]>([])
    const [openedUserDialog, setOpenedUserDialog] = useState(false)
    const [user, setUser] = useState<IUser | null>(null)
    const [newPassword, setNewPassword] = useState<string>('')
    const [updatePassword, setUpdatePassword] = useState<string>('')
    const [loadingUser, setLoadingUser] = useState(false)
    const [betaTester, setBetaTester] = useState<boolean | undefined>(false)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [isOpenNewPasswordModal, setIsOpenNewPasswordModal] = useState(false)
    const [isOpenUpdatePasswordModal, setIsOpenUpdatePasswordModal] = useState(false)
    const [companies, setCompanies] = useState<string[]>([])
    const [users, setUsers] = useState<IUser[]>([])
    const [openedCompaniesDialog, setOpenedCompaniesDialog] = useState(false)
    const [isMetricsDialogOpen, setIsMetricsDialogOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
    const [isLoadingUpdatePassword, setIsLoadingUpdatePassword] = useState(false)

    const [
        openedRequestPasswordRecoveryDialog,
        setOpenedRequestPasswordRecoveryDialog
    ] = useState<boolean>(false)
    const openCompaniesDialog = (user: IUser) => {
        setSelectedUser(user)
        setOpenedCompaniesDialog(true)
    }

    const handleCloseCompaniesDialog = () => {
        setOpenedCompaniesDialog(false)
        setSelectedUser(null)
    }

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            }
        }
    }

    const fetchRadios = async () => {
        try {
            const response = await ApiService.get('/company/list')
            return response.data
        } catch (error) {
            console.error('Erro ao buscar rádios: ', error)
            return []
        }
    }

    useEffect(() => {
        const loadRadios = async () => {
            const fetchedRadios = await fetchRadios()
            setRadios(fetchedRadios)
        }
        loadRadios()
    }, [])

    const handleChange = (event: SelectChangeEvent<typeof companies>) => {
        const {
            target: { value }
        } = event

        const newValue = Array.isArray(value) ? value : value.split(',')
        setCompanies(newValue)
    }

    const fetchUsers = async () => {
        try {
            const response = await ApiService.get('/user/list')
            return response.data
        } catch (error) {
            console.error('Erro ao buscar usuários: ', error)
            return []
        }
    }

    useEffect(() => {
        const loadUsers = async () => {
            const fetchedUsers = await fetchUsers()
            setUsers(fetchedUsers)
        }
        loadUsers()
    }, [])

    const filteredUsers = users.filter((user) => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase()

        const matchesName = user.name.toLowerCase().includes(lowerCaseSearchTerm)

        const matchesCompany = user.companyUuids.some((uuid) => {
            const company = radios.find((company: IRadio) => company.uuid === uuid)
            return company && company.name.toLowerCase().includes(lowerCaseSearchTerm)
        })

        return matchesName || matchesCompany
    })

    const handleOpenUserDialog = (userToEdit?: IUser) => {
        setNewPassword('')
        setBetaTester(user?.isBetaTester)
        setUser((prevUser) => {
            if (!prevUser) {
                return null
            }

            return {
                ...prevUser,
                product: 'codec',
                role: 'communicator',
                isBetaTester: false
            }
        })

        const initialUser = userToEdit || {
            uuid: '',
            companyUuid: '',
            name: '',
            email: '',
            product: 'metrics',
            role: 'admin',
            pictureKey: null,
            preferences: null,
            isBetaTester: false,
            createdAt: '',
            lastHeardAt: null,
            updatedAt: '',
            companyUuids: []
        }

        setUser(initialUser)
        if (initialUser.companyUuids.length === 0 && initialUser.companyUuid) {
            setCompanies([initialUser.companyUuid])
        } else {
            setCompanies(initialUser.companyUuids)
        }
        setOpenedUserDialog(true)
    }

    const handleOpenUpdatePasswordModal = async (userId: string | undefined) => {
        setOpenedRequestPasswordRecoveryDialog(false)
        setIsLoadingUpdatePassword(true)
        try {
            const { data } = await ApiService.patch(`/user/password/${userId}`)

            setUpdatePassword(data.newPassword)
            setIsOpenUpdatePasswordModal(true)
        } catch (error: any) {
            enqueueSnackbar(
                error?.data ||
                'Ocorreu um erro ao tentar alterar a senha do usuário.',
                {
                    variant: 'error'
                }
            )
        } finally {
            setIsLoadingUpdatePassword(false)
        }
    }

    const handleAccessUserAccount = async (user: IUser, version: 'v1' | 'v2' = 'v1') => {
        try {
            const { data: token } = await ApiService.post(
                `/company/${user.companyUuid}/user/${user.uuid}/token`
            )

            const versionSuffix = version === 'v2' ? '-v2' : ''
            const url = `http://${user.product}${versionSuffix}.${frontendConfig.frontendBaseUrl}/login?token=${token}`

            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            window.open(url, '_blank')?.focus()
        } catch (error: any) {
            enqueueSnackbar(
                error?.data ||
                'Ocorreu um erro ao tentar acessar a conta do usuário.',
                {
                    variant: 'error'
                }
            )
        }
    }

    const handleCloseUserDialog = () => {
        setOpenedUserDialog(false)
        setUser(null)
    }

    const handleCloseMetricsDialog = () => {
        setIsMetricsDialogOpen(false)
        setUser(null)
    }

    interface ValidationErrors {
        name?: string;
        email?: string;
        product?: string;
        companyUuids?: string;
    }

    const validateUser = (user: IUser): ValidationErrors => {
        const errors: ValidationErrors = {}

        if (!user?.name) {
            errors.name = 'O campo nome é obrigatório.'
        }
        if (!user?.email) {
            errors.email = 'O campo email é obrigatório.'
        }
        if (!user?.product) {
            errors.product = 'O campo produto é obrigatório.'
        }

        if (
            user.product !== 'intranet' &&
            (!user?.companyUuids || user?.companyUuids.length === 0)
        ) {
            errors.companyUuids = 'Pelo menos uma companhia deve ser selecionada.'
        }

        return errors
    }

    const saveUser = async () => {
        const newUser: IUser = {
            uuid: user?.uuid || undefined,
            companyUuid: companies[0],
            companyUuids: companies,
            name: user?.name || '',
            email: user?.email || '',
            product: user?.product || 'metrics',
            role: user?.role || 'admin',
            isBetaTester: user?.isBetaTester || false,
            pictureKey: user?.pictureKey || null,
            preferences: user?.preferences || null,
            createdAt: user?.createdAt || '',
            lastHeardAt: user?.lastHeardAt || null,
            updatedAt: user?.updatedAt || ''
        }

        const errors = validateUser(newUser)

        if (Object.keys(errors).length > 0) {
            Object.values(errors).forEach(error => {
                enqueueSnackbar(error, { variant: 'error' })
            })
            return
        }

        const isNewUser = !user?.uuid

        try {
            const response = await ApiService.post('/user', newUser)
            if (response.data) {
                const fetchedUsers = await fetchUsers()
                setUsers(fetchedUsers)
                enqueueSnackbar('Usuário salvo com sucesso.', { variant: 'success' })
                handleCloseUserDialog()

                if (isNewUser) {
                    setNewPassword(response.data.password)
                    setIsOpenNewPasswordModal(true)
                }
            }
        } catch (error: any) {
            console.error('Erro ao salvar usuário:', error)
            enqueueSnackbar(`${error.data}`, { variant: 'error' })
        }
    }

    const handleDeleteUser = async () => {
        setLoadingDeleteUser(true)
        try {
            await ApiService.delete(
                `/user/${user?.uuid}`
            )
            setOpenedDeleteUserDialog(false)
            setUser(prevUser => {
                if (!prevUser) return null

                return {
                    product: 'codec',
                    role: 'communicator',
                    isBetaTester: false,
                    companyUuid: prevUser.companyUuid,
                    name: prevUser.name,
                    email: prevUser.email,
                    pictureKey: prevUser.pictureKey,
                    preferences: prevUser.preferences,
                    createdAt: prevUser.createdAt,
                    lastHeardAt: prevUser.lastHeardAt,
                    updatedAt: new Date().toISOString(),
                    companyUuids: prevUser.companyUuids
                }
            })

            enqueueSnackbar('Usuário removido com sucesso.', {
                variant: 'success'
            })
        } catch (error: any) {
            enqueueSnackbar(
                error?.data || 'Ocorreu um erro ao remover o usuário.',
                {
                    variant: 'error'
                }
            )
        }

        setLoadingDeleteUser(false)
        const fetchedUsers = await fetchUsers()
        setUsers(fetchedUsers)
    }

    const [openedDeleteUserDialog, setOpenedDeleteUserDialog] =
        useState<boolean>(false)
    const [loadingDeleteUser, setLoadingDeleteUser] = useState<boolean>(false)

    const openDeleteUserDialog = async (user: IUser) => {
        setUser(user)
        setOpenedDeleteUserDialog(true)
    }

    const selectProduct = ({
        target
    }: {
        target: {
            name?: string | undefined;
            value: unknown;
        };
    }) => {
        setUser(prevUser => {
            if (!prevUser) return null

            const isBetaTesterValue = target.value === 'metrics' ? (betaTester ?? false) : false

            return {
                ...prevUser,
                product: target.value as ProductType,
                isBetaTester: isBetaTesterValue
            }
        })
    }

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(6)
    const handleChangePage = (event: any, newPage: React.SetStateAction<number>) => {
        setPage(newPage)
    }
    const handleChangeRowsPerPage = (event: { target: { value: string; }; }) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const usersToDisplay = filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    return (
        <>
            <Grid container spacing={4}>
                {/* Campo de busca e botão de criação */}
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label="Buscar por usuário ou rádio"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => handleOpenUserDialog()}
                        size="small"
                    >
                        Criar Usuário
                    </Button>
                </Grid>

                {/* Tabela de usuários */}
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="users table" >
                            <TableHead>
                                <TableRow sx={{ height: '30px' }}>
                                    <TableCell align="left">Rádio</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell align="center">E-mail</TableCell>
                                    <TableCell align="right">Produto</TableCell>
                                    <TableCell align="right" />
                                    <TableCell align="right">Ações</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usersToDisplay.map((user) => (
                                    <TableRow key={user.uuid} sx={{ height: '30px' }}>
                                        <TableCell align="left" >
                                            {user.companyUuids.length > 1
                                                ? (
                                                    <Button onClick={() => openCompaniesDialog(user)}>
                                                    Ver Rádios ({user.companyUuids.length})
                                                    </Button>
                                                )
                                                : (
                                                    radios.find(r => r.uuid === user.companyUuid)?.name || 'Nenhuma rádio'
                                                )}
                                        </TableCell>
                                        <TableCell component="th" scope="row">{user.name}</TableCell>
                                        <TableCell align="right">{user.email}</TableCell>
                                        <TableCell align="right">{user.product}</TableCell>
                                        <TableCell align="right">
                                            {user.isBetaTester && (
                                                <Chip className={classes.betaChip} size="medium" label="BETA" />
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => { setUser(user); setOpenedRequestPasswordRecoveryDialog(true) }}>
                                                <VpnKeyIcon />
                                            </IconButton>
                                            {user?.product === 'metrics'
                                                ? (
                                                    <IconButton onClick={() => { setUser(user); setIsMetricsDialogOpen(true) }} title="metrics options">
                                                        <AccessUserAccountIcon />
                                                    </IconButton>
                                                )
                                                : (
                                                    <IconButton onClick={() => handleAccessUserAccount(user)} title={user?.product}>
                                                        <AccessUserAccountIcon />
                                                    </IconButton>
                                                )}
                                            <IconButton onClick={() => handleOpenUserDialog(user)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => openDeleteUserDialog(user)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[6, 7, 8, 9, 10]}
                            component="div"
                            count={filteredUsers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>
            <Dialog
                open={openedCompaniesDialog}
                onClose={handleCloseCompaniesDialog}
            >
                <DialogTitle>Radios Associadas</DialogTitle>
                <DialogContent>
                    <Typography>
                        {selectedUser?.companyUuids
                            .map((uuid) => {
                                const company = radios.find(
                                    (r) => r.uuid === uuid
                                )
                                return company ? company.name : ''
                            })
                            .filter(Boolean)
                            .join(', ')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCompaniesDialog} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Dialog para criar/editar usuários */}
            <Dialog open={openedUserDialog}>
                <DialogTitle>
                    {!newPassword
                        ? !user?.uuid
                            ? 'Criando novo usuário'
                            : 'Editando o usuário'
                        : 'Sucesso'}
                </DialogTitle>

                {loadingUser && !user?.uuid && (
                    <Grid container justifyContent="center">
                        <CircularProgress size={80} color="primary" />
                    </Grid>
                )}

                {(!loadingUser || user?.uuid) && !newPassword && user && (
                    <>
                        <Grid container spacing={2} style={{ padding: '16px' }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Nome"
                                    size="small"
                                    variant="outlined"
                                    value={user.name}
                                    onChange={(e) =>
                                        setUser({ ...user, name: e.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    size="small"
                                    variant="outlined"
                                    value={user.email}
                                    onChange={(e) =>
                                        setUser({
                                            ...user,
                                            email: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                >
                                    <InputLabel>Produto</InputLabel>
                                    <Select
                                        value={user.product}
                                        onChange={selectProduct}
                                        variant="outlined"
                                        color="primary"
                                        label="Produto"
                                        className={classes.select}
                                    >
                                        <MenuItem value="metrics">
                                            Metrics
                                        </MenuItem>
                                        <MenuItem value="club">
                                            Clube de vantagens
                                        </MenuItem>
                                        <MenuItem value="intranet">
                                            Intranet
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                className={classes.dialogItemInput}
                            >
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    sx={{ width: 250 }}
                                >
                                    <InputLabel style={{ marginTop: -5 }} id="demo-multiple-checkbox-label">Rádio</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={companies}
                                        variant="outlined"
                                        onChange={handleChange}
                                        label="Rádio"
                                        renderValue={(selected) =>
                                            selected
                                                .map((uuid) => {
                                                    const radio = radios.find(
                                                        (r) => r.uuid === uuid
                                                    )
                                                    return radio
                                                        ? `${radio.name} - ${radio.address.city}`
                                                        : ''
                                                })
                                                .join(', ')
                                        }
                                        MenuProps={MenuProps}
                                        className={classes.select}
                                    >
                                        {radios.map((radio) => (
                                            <MenuItem
                                                key={radio.uuid}
                                                value={radio.uuid}
                                            >
                                                <Checkbox
                                                    checked={
                                                        companies.indexOf(
                                                            radio.uuid
                                                        ) > -1
                                                    }
                                                />
                                                <ListItemText
                                                    primary={`${radio.name} - ${radio.address.city}`}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={user.isBetaTester}
                                            onChange={(e) =>
                                                setUser({
                                                    ...user,
                                                    isBetaTester:
                                                        e.target.checked
                                                })
                                            }
                                        />
                                    }
                                    label="Acesso a recursos em beta"
                                />
                            </Grid>
                        </Grid>

                        <Divider />

                        <DialogActions>
                            <Button
                                onClick={handleCloseUserDialog}
                                color="primary"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={saveUser}
                                color="primary"
                                variant="contained"
                            >
                                {user.uuid ? 'Salvar' : 'Criar'}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <ShowUserPasswordModal
                password={newPassword}
                setPassword={setNewPassword}
                isOpenPasswordModal={isOpenNewPasswordModal}
                setIsOpenPasswordModal={setIsOpenNewPasswordModal}
            />

            <ShowUserPasswordModal
                password={updatePassword}
                setPassword={setUpdatePassword}
                isOpenPasswordModal={isOpenUpdatePasswordModal}
                setIsOpenPasswordModal={setIsOpenUpdatePasswordModal}
            />

            <Dialog
                aria-labelledby="simple-dialog-title"
                open={openedDeleteUserDialog}
            >
                <DialogTitle>
                    Tem certeza que deseja remover o usuário {user?.name}
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => setOpenedDeleteUserDialog(false)}
                        className={classes.deleteUserDialogCancelButton}
                        disabled={loadingDeleteUser}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDeleteUser}
                        color="primary"
                        autoFocus
                        disabled={loadingDeleteUser}
                        endIcon={
                            loadingDeleteUser && (
                                <CircularProgress size={15} color="inherit" />
                            )
                        }
                    >
                        Remover
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                aria-labelledby="simple-dialog-title"
                open={isMetricsDialogOpen && !!user}
                onClose={handleCloseMetricsDialog}
                PaperProps={{
                    style: {
                        padding: '10px 40px'
                    }
                }}
            >
                <DialogTitle>
                    <IconButton
                        onClick={
                            handleCloseMetricsDialog
                        }
                        style={{
                            position:
                                'absolute',
                            top: '5px',
                            right: '5px',
                            color: '#707070'
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogActions style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '10px', width: '200px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            handleAccessUserAccount(user!)
                            handleCloseMetricsDialog()
                        }}
                    >
                        Metrics-v1
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '0px' }}
                        fullWidth
                        onClick={() => {
                            handleAccessUserAccount(user!, 'v2')
                            handleCloseMetricsDialog()
                        }}
                    >
                        Metrics-v2
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                aria-labelledby="simple-dialog-title"
                open={openedRequestPasswordRecoveryDialog}
            >
                <DialogTitle>
                    Tem certeza que deseja solicitar a recuperação de senha para
                    o usuário {user?.name}?
                </DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() =>
                            setOpenedRequestPasswordRecoveryDialog(false)
                        }
                        className={
                            classes.RequestPasswordRecoveryDialogCancelButton
                        }
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => handleOpenUpdatePasswordModal(user?.uuid)}
                        color="primary"
                        autoFocus
                        disabled={isLoadingUpdatePassword}
                        endIcon={
                            isLoadingUpdatePassword && (
                                <CircularProgress size={15} color="inherit" />
                            )
                        }
                    >
                        Solicitar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Users
